<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card class="mb-4">
      <v-card-title>
        <div>{{ text }} User</div>
      </v-card-title>
      <v-card-text class="pb-4 pb-sm-8">
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              outlined
              dense
              name="firstName"
              label="First Name"
              v-model="data.firstName"
              :rules="[v => !!v || 'First Name Code is required']"
              id="firstName"
              placeholder="e.g. First Name of User"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              outlined
              dense
              name="lastName"
              label="Last Name"
              v-model="data.lastName"
              :rules="[v => !!v || 'Last Name is required']"
              id="lastName"
              placeholder="e.g. Last Name of User"
            />
          </v-col>
        </v-row>
        <v-row v-if="data.key">
          <v-col cols="12" sm="4" >
            <v-text-field
              outlined
              dense
              readonly
              type="text"
              name="id"
              label="Client Id"
              :value="data._id"
              id="id"
              placeholder="e.g. Client Id"
              append-icon="mdi-content-copy"
              @click:append="$copy(data._id)"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              outlined
              dense
              readonly
              type="text"
              name="key"
              label="Key"
              :value="data.key"
              id="key"
              placeholder="e.g. COnfirm Email"
              append-icon="mdi-content-copy"
              @click:append="$copy(data.key)"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              outlined
              dense
              readonly
              type="text"
              name="code"
              label="code"
              :value="data.code"
              id="code"
              placeholder="e.g. COnfirm Email"
              append-icon="mdi-content-copy"
              @click:append="$copy(data.code)"
            />
          </v-col>
        </v-row>

       <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              outlined
              dense
              type="text"
              name="url"
              label="URL"
              v-model="data.URL"
              :rules="[v => !!v || 'URL is required', v => !v ||
                  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
                    v
                  ) ||
                  'Invalid URL']"
              id="url"
              placeholder="e.g. URL"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              outlined
              dense
              type="text"
              name="company"
              label="Company"
              v-model="data.company"
              :rules="[v => !!v || 'Company is required']"
              id="company"
              placeholder="e.g. Company"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn width="100" outlined color="primary" @click="discard()">Cancel</v-btn>
        <v-btn width="100" color="primary" @click="save()" :loading="loading">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { ThirdPartyService } from '@services';
export default {
  data() {
    return {
      valid: false,
      loading: false,
      text: 'Add',
      startdatePicker: false,
      datepicker1: false,
      enddatePicker: false,
      datepicker2: false,
      data: {},
      events: [],
      errors: [],
    };
  },
  async created() {
    if (this.$route.params.id) {
      this.text = 'Edit';
      this.getById(this.$route.params.id);
    }
  },
  methods: {
    async getById(id) {
      this.data = await ThirdPartyService.getById(id);
    },
    async save() {
      if (this.$refs.form.validate() && !this.errors.length) {
        this.$confirm({
          title: `Are you sure you want to ${this.data._id ? 'update' : 'create'} this Third Party?`,
          resolve: async () => {
            this.loading = true;
            if (this.data._id) {
              await ThirdPartyService.update(this.data);
              this.$next({ name: 'admin.third-party' });
            } else {
              await ThirdPartyService.save(this.data);
              this.$next({ name: 'admin.third-party' });
            }
            this.loading = false;
          },
        });
      }
    },
    async onChangeEmail() {
      this.errors = [];
      const response = await ThirdPartyService.search(this.data.email);
      if (response && response.length > 0) {
        this.errors = ['Email already exists'];
      }
    },
    discard() {
      this.$next({ name: 'admin.third-party' });
    },
  },
};
</script>

<style scoped>
.absolute-center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 20px;
}
</style>
