<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card class="mb-4">
        <v-card-text>
          <v-subheader class="px-0 text-bold">{{ text }} venue </v-subheader>

          <v-row>
            <v-col cols="12">
              <v-text-field
                outlined
                dense
                clearable
                name="name"
                label="Name"
                v-model="data.name"
                :error-messages="errors"
                @change="onChangeName()"
                placeholder="e.g. 2 Northdown"
                :rules="[v => !!v || 'Name is required']"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="data.information"
                label="Venue Information"
                outlined
                dense
                clearable
              ></v-textarea>
            </v-col>
          </v-row>

          <v-subheader class="px-0 text-bold">Address </v-subheader>

          <v-row>
            <v-col cols="12" sm="12">
              <postcode @change="onChangePostcode" />
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                outlined
                dense
                clearable
                name="address"
                label="Venue Place"
                v-model="data.place"
                :rules="[v => !!v || 'Venue Place is required']"
                placeholder="e.g. London"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                outlined
                dense
                clearable
                name="addressline1"
                label="Address line 1"
                v-model="data.address.line1"
                placeholder="e.g. Active"
                :rules="[v => !!v || 'Address line 1 is required']"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                outlined
                dense
                clearable
                name="addressline2"
                label="Address line 2"
                v-model="data.address.line2"
                placeholder="e.g. 530 WN"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                outlined
                dense
                clearable
                name="city"
                label="Town/City"
                v-model="data.address.city"
                :rules="[v => !!v || 'Town/City Place is required']"
                placeholder="e.g. London"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                outlined
                dense
                clearable
                name="county"
                label="County"
                v-model="data.address.county"
                :rules="[v => !!v || 'County is required']"
                placeholder="e.g. Active"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                outlined
                dense
                name="zip"
                clearable
                label="Postal Code"
                v-model="data.address.postcode"
                :rules="[v => !!v || 'Postal Code is required']"
                placeholder="e.g. 530 WN"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                outlined
                dense
                name="country"
                clearable
                label="Country"
                v-model="data.address.country"
                :rules="[v => !!v || 'Country is required']"
                v-on:keypress="isLetter($event)"
                placeholder="e.g. London"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn width="100" outlined color="primary" @click="discard()">Cancel</v-btn>
          <v-btn width="100" color="primary" :loading="loading" @click="save()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>
<script>
import { VenueService } from '@services';
export default {
  data() {
    return {
      text: 'Create',
      loading: false,
      valid: false,
      errors: [],
      data: {
        name: '',
        address: {
          line1: '',
          line2: '',
          city: '',
          county: '',
          postcode: '',
          country: '',
          latitude: null,
          longitude: null,
        },
        // place: '',
        status: 'active',
      },
    };
  },
  created() {
    this.data.organiser = this.$user.organiser;
    if (this.$route.params.id) {
      this.text = 'Edit';
      this.getVenueById(this.$route.params.id);
    }
  },
  methods: {
    async onChangePostcode(address) {
      this.data.address = address;
    },
    async getVenueById(id) {
      this.data = await VenueService.getById(id);
    },
    async save() {
      if (this.$refs.form.validate() && !this.errors.length) {
        this.loading = true;
        if (this.data._id) {
          await VenueService.update(this.data);
          this.$next({ name: 'admin.venues' });
        } else {
          await VenueService.save(this.data);
          this.$next({ name: 'admin.venues' });
        }
        this.loading = false;
      }
    },
    async onChangeName() {
      this.errors = [];
      if (this.data.name) {
        const response = await VenueService.checkDuplicate(this.data.name);
        if (response) {
          this.errors = ['Venue name already exists'];
        }
      }
    },
    discard() {
      this.$next({ name: 'admin.venues' });
    },
  },
};
</script>
