<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card class="mb-4">
      <v-card-text class="pb-4 pb-sm-8">
        <v-row>
          <v-col cols="12">
            <span>{{ headingtext }} Seating Plan </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              outlined
              dense
              name="name"
              label="Name of Seating Plan"
              v-model="data.name"
              :rules="[v => !!v || 'Name of Seating Plan is required']"
              id="name"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-autocomplete
              outlined
              dense
              name="venue"
              label="Venue"
              v-model="data.venue"
              :items="venuelist"
              :rules="[v => !!v || 'Venue is required']"
              id="venue"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
              outlined
              dense
              name="seatingChart"
              label="Seating Chart"
              v-model="data.seatsioChart"
              :items="seatingChartlist"
              :rules="[v => !!v || 'Seating Chart is required']"
              id="seatingChart"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <span>Seats </span>
          </v-col>
        </v-row>
        <v-row v-for="(seats, index) in data.seats" :key="index">
          <v-col cols="12" sm="6">
            <v-autocomplete
              name="ticketcategory"
              label="Ticket Category"
              v-model="seats.category"
              :items="ticketCategorylist"
              :rules="[v => !!v || 'Ticket Category is required']"
              id="ticketcategory"
              dense
              outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              outlined
              dense
              name="blocklabel"
              label="Block label(optional)"
              v-model="seats.block"
              id="blocklabel"
            />
          </v-col>
          <v-col cols="12" v-for="(row, index) in seats.rows" :key="index">
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field outlined dense name="label" label="Row label" v-model="row.label" id="rowlabel" />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  outlined
                  dense
                  name="seatsinrow"
                  label="Seats in row"
                  v-model="row.seats"
                  id="seatsinrow"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="2">
            <v-icon class="cursor-pointer" color="primary" @click="addRow()">mdi-plus-circle-outline</v-icon>
            <b class="cursor-pointer align-middle" style="color: var(--v-primary-base)" @click="addRow()">Add Row</b>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="auto">
            <v-btn width="100" color="primary" @click="addBlock()">Add Block</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn width="100" outlined color="primary" @click="discard()">Discard</v-btn>
        <v-btn width="100" color="primary" @click="save()" :loading="loading">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { SeatingPlan, VenueService, SeatsIOService, TicketCategories } from '@services';
export default {
  data() {
    return {
      venuelist: [],
      seatingChartlist: [],
      ticketCategorylist: [],
      headingtext: 'Create',
      valid: false,
      loading: false,
      data: {
        focalPoint: { x: 0, y: 0 },
        seatMargin: 2,
        seatRadius: 10,
        name: '',
        venue: '',
        seatsioChart: '',
        seats: [
          {
            category: '',
            block: '',
            rows: [],
          },
        ],
      },
    };
  },
  async created() {
    if (this.$route.params.id) {
      this.headingtext = 'Edit';
      this.getSeatingPlanId(this.$route.params.id);
    }
    const venues = await VenueService.getAll();
    this.venuelist = venues.map(v => ({ text: v.name, value: v._id }));

    const seatioCharts = await SeatsIOService.get({ limit: 'Max'});
    if (seatioCharts) {
      this.seatingChartlist = seatioCharts.seatsIO.map(s => ({ text: s.name, value: s._id }));
    }

    const ticketCategorylist = await TicketCategories.get({limit: 'Max'});
    if (ticketCategorylist) {
      this.ticketCategorylist = ticketCategorylist.ticketCategories.map(t => ({ text: t.name, value: t._id }));
    }
  },
  methods: {
    async getSeatingPlanId(id) {
      this.data = await SeatingPlan.getById(id);
      this.data.venue = this.data.venue._id;
      this.data.seatsioChart = this.data.seatsioChart ? this.data.seatsioChart._id : '';
      this.data.seats = this.data.seats.map(s => ({
        block: s.block,
        category: s.category._id,
        rows: s.rows ? s.rows.map(r => ({ label: r.label, seats: this.setSeatString(r.seats) })) : [],
      }));
    },
    async save() {
      if (this.$refs.form.validate()) {
        this.$confirm({
          title: `Are you sure you want to ${this.data._id ? 'update' : 'create'} this Seating Plan?`,
          resolve: async () => {
            this.loading = true;
            this.data.seats.map((e, i) => {
              if (e.rows) {
                e.rows.map((r, j) => {
                  this.data.seats[i].rows[j].seats = this.setSeatArray(r.seats);
                });
              }
            });
            if (this.data._id) {
              await SeatingPlan.update(this.data);
              this.$next({ name: 'admin.seating-plan' });
            } else {
              await SeatingPlan.save(this.data);
              this.$next({ name: 'admin.seating-plan' });
            }
            this.loading = false;
          },
        });
      }
    },
    discard() {
      this.$next({ name: 'admin.seating-plan' });
    },
    addBlock() {
      this.data.seats.push({
        category: '',
        block: '',
        rows: [],
      });
    },
    addRow() {
      this.data.seats[this.data.seats.length - 1].rows.push({
        label: '',
        seats: [],
      });
    },
    setSeatArray(seats) {
      seats = String(seats);
      var seatArray = seats.split(',');
      seatArray.map((e, i) => {
        var seatLength = e.split('-');
        if (seatLength.length > 1) {
          seatArray[i] = seatLength[0];
          for (let s = parseInt(seatLength[0]) + 1; s <= parseInt(seatLength[seatLength.length - 1]); s++) {
            seatArray.push(s);
          }
        }
      });
      return seatArray;
    },
    setSeatString(arr) {
      var seatArrayApi = arr.map(s => parseInt(s));
      var seatArrayApiString = '';
      seatArrayApi.sort(function(a, b) {
        return a - b;
      });
      seatArrayApiString = seatArrayApi[0];
      seatArrayApi.map((s, i) => {
        if (s + 1 == seatArrayApi[i + 1] || i == 0) {
          return;
        }
        if (s - 1 != seatArrayApi[i - 1]) {
          seatArrayApiString = seatArrayApiString + ',' + seatArrayApi[i];
        } else {
          seatArrayApiString = seatArrayApiString + '-' + seatArrayApi[i];
          if (s + 1 != seatArrayApi[i + 1] && i + 1 < seatArrayApi.length) {
            seatArrayApiString = seatArrayApiString + ',' + seatArrayApi[i + 1];
          }
        }
      });
      return seatArrayApiString;
    },
  },
};
</script>

<style scoped>
.absolute-center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 20px;
}
</style>
