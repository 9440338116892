<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card class="mb-4">
      <v-card-title>
        <div>{{ text }} SeatsIO Chart</div>
      </v-card-title>
      <v-card-text class="pb-4 pb-sm-8">
        <v-row>
          <v-col cols="12" sm="12">
            <v-text-field
              outlined
              dense
              clearable
              name="firstName"
              @input="nameError=[]"
              :error-messages="nameError"
              @change="onChangeName()"
              label="Name"
              v-model="data.name"
              :rules="[v => !!v || 'Name is required']"
              id="firstName"
              placeholder="e.g. Name of Chart"
            />
          </v-col>
        </v-row>
        <v-row v-if="isChartDisplay">
          <v-col cols="12" sm="12">
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title>Please keep this points on check while creating a chart</v-list-item-title>
                <v-list-item-subtitle>
                  1) No Duplicate objects.
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  2) All objects are labeled.
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  3) All objects are categorized.
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  4) Every category applied to one object type.
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  5) Focal point is set.
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row v-if="isChartDisplay">
          <v-col cols="12" sm="12">
            <div id="chartDesigner" style="height:800px;margin-top: 16px;"></div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn width="100" outlined color="primary" @click="discard()">Cancel</v-btn>
        <v-btn width="200" color="primary" @click="save()" :loading="loading">Save & Continue</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { SeatsIOService } from '@services';
export default {
  data() {
    return {
      valid: false,
      loading: false,
      text: 'Add',
      isChartDisplay: true,
      data: {},
      nameError: [],
    };
  },
  async created() {
    if (this.$route.params.id) {
      this.text = 'Edit';
      this.getById(this.$route.params.id);
    } else {
      this.isChartDisplay = false;
    }
  },
  methods: {
    async getById(id) {
      this.data = await SeatsIOService.getById(id);
      this.prepareChart();
    },
    async prepareChart() {
      this.chartHolder = new window.seatsio.SeatingChartDesigner({
        divId: 'chartDesigner',
        designerKey: process.env.VUE_APP_SEAT_IO_API_KEY,
        chartKey: this.data.chartKey,
        language: 'en',
        openDraftDrawing: true,
        openLatestDrawing: true,
        onChartCreated: function(chartKey) {
          console.log('Chart Created', chartKey);
        },
        onChartUpdated: function(chartKey) {
          console.log('Chart Updated', chartKey);
        },
        onChartPublished: function(chartKey) {
          console.log('Chart Published', chartKey);
        },
        onExitRequested: function() {
          console.log('Chart Exit');
        },
        onDesignerRenderingFailed: function(designer) {
          designer.destroy();
        },
      }).render();
    },
    async save() {
      if (this.$refs.form.validate() && !this.nameError.length) {
        this.$confirm({
          title: `Are you sure you want to ${this.data._id ? 'update' : 'create'} this seatsIO?`,
          resolve: async () => {
            this.loading = true;
            if (this.data._id) {
              const response = await SeatsIOService.update(this.data);
              if (response) {
                this.$next({ name: 'admin.seats-io-designer' });
              }
            } else {
              const response = await SeatsIOService.save(this.data);
              if (response) {
                this.isChartDisplay = true;
                this.text = 'Edit';
                this.data = response.data;
                this.getById(response.data._id);
                this.$next({ name: 'admin.seats-io-designer.edit', params: { id: response.data._id } });
              }
            }
            this.loading = false;
          },
        });
      }
    },
    discard() {
      this.$next({ name: 'admin.seats-io-designer' });
    },
    async onChangeName() {
      this.nameError = [];
      if (this.data.name) {
        const response = await SeatsIOService.checkDuplicate(this.data.name);
        if (response) {
          if (response._id != this.data._id) {
            this.nameError = ['Name already exists'];
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.absolute-center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 20px;
}
</style>
