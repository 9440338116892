<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card class="mb-4">
      <v-card-title>
        <div>{{ text }} Ticket Category</div>
      </v-card-title>
      <v-card-text class="pb-4 pb-sm-8">
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              outlined
              dense
              name="name"
              clearable
              label="Name"
              v-model="data.name"
              :rules="[v => !!v || 'Name is required']"
              id="name"
              @input="nameError = []"
              :error-messages="nameError"
              @change="onChangeName()"
              placeholder="e.g. Name"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
              v-model="data.organiser"
              :items="organisers"
              clearable
              :filter="customFilter"
              item-text="name"
              item-value="_id"
              label="Promoter"
              :rules="[v => !!v || 'Promoter is required']"
              dense
              outlined
            >
              <template v-slot:selection="data">
              <span>{{ data.item.name }} ( {{ data.item.type }} )</span>
              </template>
              <template v-slot:item="data">
                <span>{{ data.item.name }} ( {{ data.item.type }} )</span>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-color-picker v-model="data.color" mode="hexa" flat hide-mode-switch></v-color-picker>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn width="100" outlined color="primary" @click="discard()">Cancel</v-btn>
        <v-btn width="100" color="primary" @click="save()" :loading="loading">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import { PromoterService, TicketCategories, EventTypeService, CategoryService, SubCategoryService } from '@services';
export default {
  data() {
    return {
      valid: false,
      loading: false,
      text: 'Add',
      data: {
        name: '',
        organiser: '',
        // eventType: '',
        // genre: '',
        // subCategory: '',
        color: null,
      },
      organisers: [],
      eventTypes: [],
      genres: [],
      subcategories: [],
      nameError: [],
    };
  },
  async created() {
    if (this.$route.params.id) {
      this.text = 'Edit';
      this.getTicketCategoryById(this.$route.params.id);
    }
    const promoterdata = await PromoterService.get({ limit: 'all' });
    this.organisers = promoterdata.organisers;
    const eventTypes = await EventTypeService.get({ limit: 'all' });
    this.eventTypes = eventTypes.eventTypes;
    const genres = await CategoryService.get({ limit: 'all' });
    this.genres = genres.genres;
    this.getSubCategory();
  },
  methods: {
    async getTicketCategoryById(id) {
      this.data = await TicketCategories.getById(id);
      // this.data.genre = this.data.genre ? this.data.genre._id : '';
      this.data.organiser = this.data.organiser ? this.data.organiser._id : '';
      // this.data.eventType = this.data.eventType ? this.data.eventType._id : '';
      this.getSubCategory();
    },
    customFilter (item, queryText) {
      const textOne = `${item.name.toLowerCase()} - ${item.type.toLowerCase()}`
      const searchText = queryText.toLowerCase()
      return textOne.indexOf(searchText) > -1 
    },
    async getSubCategory() {
      const subCategoryData = await SubCategoryService.get({ genre: this.data.genre, limit: 'all' });
      this.subcategories = subCategoryData.subCategories;
    },
    async save() {
      if (this.$refs.form.validate() && !this.nameError.length) {
        this.$confirm({
          title: `Are you sure you want to ${this.data._id ? 'update' : 'create'} this ticket-category?`,
          resolve: async () => {
            this.loading = true;
            if (this.data._id) {
              await TicketCategories.update(this.data);
              this.$next({ name: 'admin.ticket-category' });
            } else {
              this.data.color = this.data.color.hex;
              await TicketCategories.save(this.data);
              this.$next({ name: 'admin.ticket-category' });
            }
            this.loading = false;
          },
        });
      }
    },
    discard() {
      this.$next({ name: 'admin.ticket-category' });
    },
    async onChangeName() {
      this.nameError = [];
      if (this.data.name) {
        const response = await TicketCategories.checkDuplicate(this.data.name);
        if (response) {
          if (response._id != this.data._id) {
            this.nameError = ['Name already exists'];
          }
        }
      }
    },
  },
};
</script>
<style scoped>
.absolute-center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 20px;
}
</style>
